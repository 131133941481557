export default {
  top_home_page_wrapper: 'HomePageMegaMenu',
  hero_banner_carousel: 'HomePageHeroBanner',
  mediclub_bottom_banner: 'HomePageBottomBanner',
  ad_banners: 'HomePageImagesBanners',
  popular_brands_carousel: 'MediSnapCarouselHomePage',
  promo_offers_carousel: 'MediSnapCarouselHomePage',
  mediclub_carousel: 'MediSnapCarouselHomePage',
  blog_posts_carousel: 'MediSnapCarouselHomePage',
  popular_sections_carousel: 'MediSnapCarouselHomePageTabs',
  popular_products_tabs_carousel: 'MediSnapCarouselHomePageTabs',
  popular_products_tabs_carousel_2: 'MediSnapCarouselHomePageTabs',
  purchase_steps: 'HomePageSteps',
  top_wide_ad_banner: 'HomePageAnimatedText',
  appointment_services_search_widget: 'HomePageSearchWidget',
  top_icons_list: 'HomePageFourIcons',
  virtual_categories_list: 'HomePageVirtualCategoriesList',
  virtual_categories: 'HomePageVirtualCategories',
  virtual_categories_items: 'HomePageSlot',
  virtual_categories_column: 'HomePageVirtualCategoriesColumn',
  virtual_categories_item: 'HomePageVirtualCategoriesItem',
  header: 'HomePageHeader',
  description: 'HomePageDescription',
  faq: 'HomePageFaq',
  links_section_list: 'HomePageSelectionLinksList',
}
