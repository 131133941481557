export default {
  // default: {
  //   pl: 'Medistore - sklep pełen zdrowia | Największy marketplace zdrowotny w Polsce',
  //   en: 'Medistore - your place full of health | The largest healthcare marketplace in Poland',
  // },
  medicoverapteka: {
    pl: 'Suplementy Medicover Vital - wsparcie dla zdrowia i równowagi ',
    en: 'Medicover Vital Supplements – Supporting Health and Balance',
  },
}
